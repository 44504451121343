import React from 'react'
import {Global, css} from '@emotion/react'
import Header from './header'
import {Helmet} from 'react-helmet'
import useSiteMetaData from '../hooks/use-sitemetadata'
import '../vars.css'

const Layout = ({children}) => {
  const {title, description} = useSiteMetaData()
  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          /* More info: https://bit.ly/2PsCnzk */
          * + * {
            margin-top: 1rem;
          }

          html,
          body {
            margin: 0;
            color: var(--on-primary-color);
            font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',
              Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';
            font-size: 14px;
            line-height: 1.4;
            @media (min-width: calc(550px + 10vw)) {
              font-size: 18px;
            }
            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }
            background: var(--primary-color);
          }

          body {
            min-height: 100vh;
            border: 10px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(
              180deg,
              #ffabe0,
              #b188f9,
              #98acf7
            );
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: var(--on-primary-color);
            line-height: 1.1;
            + * {
              margin-top: 0.5rem;
            }
          }
          strong {
            color: var(--secondary-color);
          }
          li {
            margin-top: 0.25rem;
          }
          a {
            color: var(--secondary-color);
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <main
        css={css`
          margin: 0rem auto 2rem;
          max-width: 90vw;
          width: 550px;
          padding-top: 2vh;
        `}
      >
        <Header />
        {children}
      </main>
    </>
  )
}

export default Layout
