import React from 'react'
import styled from '@emotion/styled'
import {css} from '@emotion/react'
import {Link} from 'gatsby'

const NavLink = styled(Link)`
  color: #493c66;
  font-size: 1rem;
  font-weight: ${(props) => props.fontWeight || 'bold'};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem 0.25rem 0.25rem 0rem;
  text-decoration: none;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    color: var(--secondary-color);
    text-decoration: none;
    opacity: 0.5;
  }
  &.current-page {
    color: var(--secondary-color);
  }
`

const Header = () => (
  <header
    css={css`
      display: flex;
      padding: 0rem 5vw 1rem 0rem;
    `}
  >
    <NavLink to="/" activeClassName="current-page" aria-label="my posts">
      Posts
    </NavLink>
    <NavLink to="/now/" activeClassName="current-page" aria-label="my now page">
      Now
    </NavLink>
    <NavLink
      to="https://pinterest.com/kamalnrf"
      target="_blank"
      rel="noreferrer"
      aria-label="my pinterest"
    >
      Pinterest
    </NavLink>
    <NavLink
      to="https://twitter.com/kamalnrf"
      target="_blank"
      rel="noreferrer"
      aria-label="my twitter"
    >
      Twitter
    </NavLink>
    <NavLink
      to="https://github.com/kamalnrf"
      target="_blank"
      rel="noreferrer"
      aria-label="my github"
    >
      Github
    </NavLink>
  </header>
)

export default Header
